ag-grid-angular div.ag-center-cols-viewport {
    overflow-y: hidden;
    overflow-x: none;
}

ag-grid-angular div.ag-center-cols-clipper {
    max-height: none !important
}

ag-grid-angular div.ag-floating-bottom {
    overflow-y: hidden !important;
}